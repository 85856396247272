<template>
  <item-edit-view
    title="Пользователь"
    :subtitle="item ? item.username : null"
    :loading="loading"
  >
    <template v-slot:right>
      <v-btn v-can="'login_to_account User'"
        @click.prevent="switchToAccount"
        class="mr-1"
      >
        Войти в лк
      </v-btn>
      <v-btn
        right
        color="success"
        :loading="saving"
        @click.prevent="save"
      >
        <v-icon small>mdi-content-save</v-icon>
        Сохранить
      </v-btn>
    </template>

    <v-card>
      <v-form v-if="!loading && item">
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tab key="profile">Профиль</v-tab>
          <v-tab key="logs">Лог</v-tab>
        </v-tabs>

        <v-card-text>
          <v-tabs-items v-model="tab">
            <v-tab-item key="profile">
              <v-text-field
                v-model="item.createdAt"
                label="Зарегистрирован"
                disabled
              ></v-text-field>

              <validation-provider
                v-slot="{ errors }"
                name="Логин"
                rules="required"
              >
                <v-text-field
                  v-model="item.username"
                  label="Логин"
                  :error-messages="errors"
                  required
                ></v-text-field>
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                name="Фио"
                rules="required"
              >
                <v-text-field
                  v-model="item.fullName"
                  label="Фио"
                  :error-messages="errors"
                  required
                  disabled
                ></v-text-field>
              </validation-provider>

              <validation-provider
                v-if="isPatient"
                v-slot="{ errors }"
                name="Дата рождения"
                rules="required"
              >
                <v-menu
                  ref="birthdayMenu"
                  v-model="birthdayMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  disabled
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.birthday"
                      label="Дата рождения"
                      disabled
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="birthdayPicker"
                    v-model="item.birthday"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1900-01-01"
                    @change="setBirthday"
                    :error-messages="errors"
                  ></v-date-picker>
                </v-menu>
              </validation-provider>

              <v-text-field
                v-model="item.ehrId"
                label="Ehr id"
                disabled
              ></v-text-field>

              <validation-provider
                v-slot="{ errors }"
                name="PID"
                :rules="{ required: isPatient }"
              >
                <v-text-field
                  v-model="item.recordId"
                  label="PID"
                ></v-text-field>
              </validation-provider>

              <v-text-field
                v-model="item.vkId"
                label="Vk id"
              ></v-text-field>

              <v-text-field
                v-model="item.facebookId"
                label="Facebook id"
              ></v-text-field>

              <v-text-field
                v-model="item.googleId"
                label="Google id"
              ></v-text-field>

              <v-text-field
                v-model="item.telegramId"
                label="Telegram id"
              ></v-text-field>

              <v-container fluid>
                <p>Роли</p>
                <v-checkbox v-for="role in roles"
                  v-model="item.roles"
                  :label="roleLabels[role]"
                  :value="role"
                  hide-details
                ></v-checkbox>
              </v-container>

            </v-tab-item>
            <v-tab-item key="logs">
              <el-table
                v-loading="logs.inProcess"
                :data="logs.items"
                :empty-text="'Операций еще не было'"
                key="id"
                border
                fit
                highlight-current-row
                style="width: 100%;"
              >
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <json-viewer :value="JSON.parse(props.row.diffs)"></json-viewer>
                  </template>
                </el-table-column>

                <el-table-column
                  label="Id"
                  prop="id"
                >
                </el-table-column>

                <el-table-column
                  label="Тип операции"
                  prop="type"
                >
                </el-table-column>

                <el-table-column
                  label="Дата"
                  prop="created_at"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.created_at | date }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  label="Изменил"
                  prop="blame_id"
                >
                  <template slot-scope="scope">
                    <router-link v-if="scope.row.blame_id" :to="{ name: 'User', params: { id: scope.row.blame_id }}" target="_blank">
                      {{ scope.row.blame_user }}
                    </router-link>
                    <span v-else>{{ scope.row.blame_user }}</span>
                  </template>
                </el-table-column>

                <el-table-column
                  label="IP"
                  prop="ip"
                >
                </el-table-column>
              </el-table>

              <c-pagination
                v-show="logs.pages > 0"
                :total="logs.total"
                :page.sync="logs.page"
                :limit.sync="logs.limit"
                @pagination="fetchLogs"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-form>
    </v-card>
  </item-edit-view>
</template>

<script>
import userService from '../../services/users'
import auth from '../../services/auth'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import Datepicker from 'vue-bulma-datepicker'
import { mapGetters } from 'vuex'
import Pagination from '../../components/elements/c-pagination'
import JsonViewer from 'vue-json-viewer'
import {
  ROLE_UNREGISTERED_CLIENT,
  ROLE_CLIENT,
} from '../../models/AuthUser'

export default {
  mixins: [createOrUpdateViewMixin(userService)],
  data () {
    return {
      roles: auth.roles,
      roleLabels: auth.roleLabels,
      birthdayMenu: false,
      tab: null,
      logs: {
        items: [],
        limit: 30,
        page: 1,
        pages: 0,
        total: 0,
        inProcess: true
      }
    }
  },
  components: {
    Datepicker,
    'c-pagination': Pagination,
    'json-viewer': JsonViewer
  },
  mounted () {
    this.fetchLogs()
  },
  computed: {
    ...mapGetters([
      'currentUser'
    ]),
    isPatient() {
      if (this.loading) {
        return false
      }
      return this.item.roles.indexOf(ROLE_CLIENT) !== -1 ||
        this.item.roles.indexOf(ROLE_UNREGISTERED_CLIENT) !== -1
    }
  },
  watch: {
    birthdayMenu (val) {
      val && setTimeout(() => (this.$refs.birthdayPicker.activePicker = 'YEAR'))
    },
  },
  methods: {
    async switchToAccount () {
      if (this.isPatient) {
        const resp = await userService.switchToAccount(this.item.id)
        window.open(resp.data.redirectUrl, '_blank')
        return
      }
      await userService.switchToAdminAccount(this.item.username)
      location.reload()
    },
    setBirthday(date) {
      this.$refs.birthdayMenu.save(date)
    },
    fetchLogs() {
      this.logs.inProcess = true
      userService.logs(this.$route.params.id, {
        limit: this.logs.limit,
        page: this.logs.page
      }).then(resp => {
        this.logs.items = resp.items
        this.logs.total = resp.total
        this.logs.pages = resp.pages
        this.logs.limit = resp.limit
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.logs.inProcess = false
      })
    }
  }
}
</script>
